export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'return-statuses/edit/'
  },
  { text: 'Статус', value: 'name' }
]
